import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function App() {

  const [usuarios, setUsuarios]= useState([]);
  const [tablaUsuarios, setTablaUsuarios]= useState([]);
  const [busqueda, setBusqueda]= useState("");

  const peticionGet=async()=>{
    await axios.get("https://www.cloccidental.com/webservice/p_coordinadores10.php")
    .then(response=>{
      setUsuarios(response.data);
      setTablaUsuarios(response.data);
    }).catch(error=>{
      console.log(error);
    })
  }

  const handleChange=e=>{
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  }

  const filtrar=(terminoBusqueda)=>{
    var resultadosBusqueda=tablaUsuarios.filter((elemento)=>{
      if(elemento.vendedor.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      || elemento.nombrevend.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      ){
        return elemento;
      }
    });
    setUsuarios(resultadosBusqueda);
  }

  useEffect(()=>{
    peticionGet();
  },[])

  return (
    <div className="App">
      <div className='containerInput'>
        <input
          className='form-control inputBuscar'
          value={busqueda}
          placeholder="Búsqueda por nombre o código del vendedor"
          onChange={handleChange}
        />
        <button className='btn btn-success'>
          <FontAwesomeIcon icon={faSearch}/>
        </button>
      </div>
      <div className='table-responsive'>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr>
              <th>Coordinador</th>
              <th>Vendedor</th>
              <th>Nombre</th>
              <th>Cant. Ped.</th>
              <th>Monto Ped.</th>
              <th>Fact. Ped.</th>
              <th>Mto. Fact.</th>
              <th>N/C DEV</th>
              <th>N/C PP</th>
              <th>Neto Fac.</th>
              <th>Meta</th>
              <th>% Alcanz.</th>
              <th>Cli.</th>
              <th>Visit.</th>
              <th>%Efect.</th>
            </tr>
          </thead>
          <tbody>
            {usuarios &&
            usuarios.map((usuarios)=>(
              <tr key={usuarios.vendedor}>
                <td>{usuarios.codcoord}</td>
                <td>{usuarios.vendedor}</td>
                <td>{usuarios.nombrevend}</td>
                <td>{usuarios.cntpedidos}</td>
                <td>{usuarios.mtopedidos}</td>
                <td>{usuarios.cntfacturas}</td>
                <td>{usuarios.mtofacturas}</td>
                <td>{usuarios.devdol_totneto}</td>
                <td>{usuarios.ppgdol_totneto}</td>
                <td>{Math.ceil((usuarios.mtofacturas - usuarios.devdol_totneto - usuarios.ppgdol_totneto)*100)/100}</td>
                <td>{usuarios.metavend}</td>
                <td>{usuarios.prcmeta}</td>
                <td>{usuarios.cntclientes}</td>
                <td>{usuarios.clivisit}</td>
                <td>{usuarios.prcvisitas}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
